import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import Navbar from './Navbar.js';
import Hero from './Hero.js';
import useScrollToTop from '../hooks/useScrollToTop.js';

const Home = () => {
  useScrollToTop();

  const testimonials = [
    {
      quote: "I am impressed with Mr. Kieta's high-quality work and his attention to detail. I highly recommended him.",
      author: "Cecilia Arzewski",
      title: "Concertmaster, Atlanta Symphony Orchestra"
    },
    {
      quote: "Mr. Kieta is an expert of the highest caliber in repairing and restoring fine stringed instruments. He gives careful attention to details and has the capability of making any instrument sound its very best.",
      author: "Sonja Foster",
      title: "Performer and Teacher"
    },
    {
      quote: "I have found Mr. Kieta to be a first rate craftsman and also a knowledgeable dealer. Atlanta and the Southeast are fortunate to have someone of his caliber.",
      author: "Christopher Rex",
      title: "Principal Cellist, Atlanta Symphony Orchestra"
    }
  ];

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.content-wrapper').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="home">
      <Navbar />
      <Hero 
        title="Welcome to BK Violins"
        subtitle="Serving the Southeast with fine stringed instruments since 1993"
      />

      <section className="content-section">
        <div className="content-wrapper">
          <h2>Experience</h2>
          <p>BK Violins is owned and operated by Boguslaw Kieta. Mr. Kieta brings over 40 years of experience making and repairing string instruments to the Atlanta area. Serving the Southeast area since 1993, BK Violins offers the finest premium stringed instruments for rental or purchase.</p>
          <Link to="/about" className="learn-more">Learn More</Link>
        </div>
      </section>

      <section className="content-section alt">
        <div className="content-wrapper">
          <h2>Quality</h2>
          <p>The most important aspect of BK Violins is the quality of instruments that are available to rent. Every single instrument that we have is suited with the highest professional standards. You can always be sure that you will be receiving a quality instrument and excellent service from BK Violins.</p>
          <Link to="/rentals" className="learn-more">Learn More</Link>
        </div>
      </section>

      <section className="content-section">
        <div className="content-wrapper">
          <h2>For Purchase</h2>
          <p>BK Violins does carry instruments for sale, so if you are in the market it is advised to check with us to see what we have available. Even if we do not have what you are looking for; we will do our best to locate your desired instrument and get it into your hands.</p>
          <Link to="/purchase" className="learn-more">Learn More</Link>
        </div>
      </section>

      <section className="content-section alt">
        <div className="content-wrapper">
          <h2>Start Renting Today</h2>
          <p>We have created an easy way to start your rental with BK Violins. Simply follow the link below and fill out the form to start your rental experience with BK Violins. If you have any further questions, feel free to contact us so we can guide you through the process.</p>
          <Link to="/rentals" className="learn-more">Learn More</Link>
        </div>
      </section>

      <section className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <p className="quote">{testimonial.quote}</p>
              <p className="author">{testimonial.author}</p>
              <p className="title">{testimonial.title}</p>
            </div>
          ))}
        </div>
      </section>

      <footer className="footer">
        <div className="contact-info">
          <p>404-966-1834</p>
          <p>bk@bkviolins.com</p>
          <p>4177 Loch Highland Pkwy NE Roswell, GA 30075</p>
        </div>
        <p className="copyright">©2024. BK Violins. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
