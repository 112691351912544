import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [rentalCart, setRentalCart] = useState(() => {
    const saved = localStorage.getItem('rentalCart');
    return saved ? JSON.parse(saved) : [];
  });
  
  const [accessoryCart, setAccessoryCart] = useState(() => {
    const saved = localStorage.getItem('accessoryCart');
    return saved ? JSON.parse(saved) : [];
  });

  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    localStorage.setItem('rentalCart', JSON.stringify(rentalCart));
    localStorage.setItem('accessoryCart', JSON.stringify(accessoryCart));
  }, [rentalCart, accessoryCart]);

  const addToCart = (item) => {
    if (item.isAccessory) {
      setAccessoryCart([...accessoryCart, item]);
    } else {
      setRentalCart([...rentalCart, item]);
    }
  };

  const removeFromRentalCart = (index) => {
    setRentalCart(rentalCart.filter((_, i) => i !== index));
  };

  const removeFromAccessoryCart = (index) => {
    setAccessoryCart(accessoryCart.filter((_, i) => i !== index));
  };

  const calculateRentalTotal = () => {
    return rentalCart.reduce((total, item) => 
      total + item.price + (item.maintenanceFee || 0), 0);
  };

  const calculateAccessoryTotal = () => {
    return accessoryCart.reduce((total, item) => total + item.price, 0);
  };

  return (
    <CartContext.Provider 
      value={{ 
        rentalCart,
        accessoryCart, 
        showCart, 
        setShowCart, 
        addToCart, 
        removeFromRentalCart,
        removeFromAccessoryCart,
        calculateRentalTotal,
        calculateAccessoryTotal,
        setRentalCart,
        setAccessoryCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext); 