import React, { useEffect } from 'react';
import { useCart } from '../context/CartContext.js';
import '../styles/rentals.css';
import violin from '../styles/images/violin.jpg';
import viola from '../styles/images/viola.jpg';
import cello from '../styles/images/cello.jpg';
import bass from '../styles/images/bass.jpg';
import Navbar from './Navbar.js';
import Hero from './Hero.js';
import oldFiddle from '../styles/images/old-fiddle.jpg';
import useScrollToTop from '../hooks/useScrollToTop.js';

const Rentals = () => {
  useScrollToTop();
  const { addToCart } = useCart();

  const instruments = {
    violin: {
      name: "Violin",
      image: violin,
      sizes: [
        { size: "1/32", price: 30 },
        { size: "1/16", price: 30 },
        { size: "1/8", price: 30 },
        { size: "1/4", price: 30 },
        { size: "1/2", price: 35 },
        { size: "3/4", price: 35 },
        { size: "4/4", price: 35 }
      ],
      maintenanceFee: 10
    },
    viola: {
      name: "Viola",
      image: viola,
      sizes: [
        { size: '8"', price: 30 },
        { size: '10"', price: 30 },
        { size: '11"', price: 30 },
        { size: '12"', price: 35 },
        { size: '13"', price: 35 },
        { size: '14"', price: 35 },
        { size: '15"', price: 40 },
        { size: '15 1/2"', price: 40 },
        { size: '16"', price: 40 },
        { size: '16 1/2"', price: 40 }
      ],
      maintenanceFee: 12
    },
    cello: {
      name: "Cello",
      image: cello,
      sizes: [
        { size: "1/10", price: 45 },
        { size: "1/8", price: 45 },
        { size: "1/4", price: 45 },
        { size: "1/2", price: 50 },
        { size: "3/4", price: 50 },
        { size: "4/4", price: 50 }
      ],
      maintenanceFee: 15
    },
    bass: {
      name: "Bass",
      image: bass,
      sizes: [
        { size: "1/10", price: 65 },
        { size: "1/8", price: 65 },
        { size: "1/4", price: 65 },
        { size: "1/2", price: 65 },
        { size: "3/4", price: 65 }
      ],
      maintenanceFee: 20
    }
  };

  const accessories = [
    { name: "Music Stand", price: 17, isAccessory: true },
    { name: "Kun Collapsible Shoulder Rest", price: 30, isAccessory: true },
    { name: "Violin/Viola Stand", price: 60, isAccessory: true },
    { name: "Cello Stand", price: 110, isAccessory: true },
    { name: "Bass Stand", price: 110, isAccessory: true },
    { name: "Rock Stop", price: 12, isAccessory: true }
  ];

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.rental-info, .category-header').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="rentals-page">
      <Navbar />
      <Hero 
        title="Instrument Rentals" 
        subtitle="Premium Quality Instruments for Musicians of All Levels"
      />

      <section className="rentals-content">
        <div className="rental-info">
          <h2>Premium Rental Program</h2>
          <p>All rental instruments are hand carved with a spruce top and flamed maple back and sides, hand painted with spirit varnish, and have ebony fittings. Each rental includes a quality bow, protective case, rosin, and cleaning cloth.</p>
        </div>

        <div className="instruments-section">
          {Object.entries(instruments).map(([key, instrument]) => (
            <div 
              key={key} 
              className="instrument-category"
              data-instrument={key}
            >
              <div className="category-header">
                <img 
                  src={instrument.image} 
                  alt={instrument.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = oldFiddle;
                  }}
                />
                <div className="category-info">
                  <h2>{instrument.name}</h2>
                  <p>Monthly maintenance fee: ${instrument.maintenanceFee}/mo</p>
                  <div className="sizes-grid">
                    {instrument.sizes.map((sizeOption, index) => (
                      <div key={index} className="size-card">
                        <h3>{sizeOption.size}</h3>
                        <p className="price">${sizeOption.price}/mo</p>
                        <button 
                          onClick={() => addToCart({
                            name: instrument.name,
                            size: sizeOption.size,
                            price: sizeOption.price,
                            maintenanceFee: instrument.maintenanceFee
                          })}
                        >
                          Add
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="accessories-section">
          <h2>Optional Accessories</h2>
          <div className="accessories-grid">
            {accessories.map((accessory, index) => (
              <div key={index} className="accessory-card">
                <h3>{accessory.name}</h3>
                <p className="price">${accessory.price}</p>
                <p className="one-time">One-time fee</p>
                <button onClick={() => addToCart(accessory)}>
                  Add
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="contact-info">
          <p>404-966-1834</p>
          <p>bk@bkviolins.com</p>
          <p>4177 Loch Highland Pkwy NE Roswell, GA 30075</p>
        </div>
        <p className="copyright">©2024. BK Violins. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Rentals;
