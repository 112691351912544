import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Cart from './Cart.js';
import '../styles/navbar.css';
import logo from '../styles/images/logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinks = [
    { to: '/', text: 'Home' },
    { to: '/about', text: 'About' },
    { to: '/rentals', text: 'Rentals' },
    { to: '/purchase', text: 'Purchase' },
    { to: '/contact', text: 'Contact' }
  ];

  return (
    <nav className="navbar">
      <button 
        className="menu-toggle" 
        onClick={toggleMenu}
        aria-expanded={isMenuOpen}
      >
        <span className="hamburger"></span>
      </button>

      <div className="logo">
        <img src={logo} alt="BK Violins" />
      </div>

      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        {navLinks.map((link, index) => (
          <Link key={index} to={link.to} onClick={() => setIsMenuOpen(false)}>
            {link.text}
          </Link>
        ))}
      </div>
      
      <Cart />
    </nav>
  );
};

export default Navbar; 