import React from 'react';
import oldFiddle from '../styles/images/old-fiddle.jpg';
import '../styles/hero.css';

const Hero = ({ title, subtitle, backgroundImage = oldFiddle }) => {
  return (
    <header 
      className="hero" 
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <h1>{title}</h1>
      {subtitle && <p>{subtitle}</p>}
    </header>
  );
};

export default Hero; 