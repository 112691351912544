import React, { useEffect } from 'react';
import '../styles/about.css';
import Navbar from './Navbar.js';
import Hero from './Hero.js';
import kieta from '../styles/images/kieta.jpg';
import useScrollToTop from '../hooks/useScrollToTop.js';

const About = () => {
  useScrollToTop();

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.about-section').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="about-page">
      <Navbar />
      <Hero 
        title="About BK Violins" 
        subtitle="Over 45 years of expertise in fine stringed instruments"
      />

      <section className="about-content">
        <div className="about-section founder">
          <div className="founder-image">
            <img src={kieta} alt="Boguslaw Kieta" />
          </div>
          <div className="founder-info">
            <h2>Our Story</h2>
            <p>Boguslaw Kieta started playing the violin at the young age of 9. When he was 15, he went to violinmaking school in Poland. He later relocated to New York City, where he spent 4 years at the prestigious Jacques Francais Rare Violins, repairing and restoring some of the finest string instruments in the world.</p>
            <p>Mr. Kieta moved to Atlanta, Georgia in 1993 and opened his own studio. After 40 years of working with professional musicians, he decided to begin an instrument rental program for young musicians. Not only is each of his instruments carefully hand selected to ensure quality, but he also sets up every instrument himself, in order to guarantee that it is up to its best playable condition.</p>
          </div>
        </div>

        <div className="about-section">
          <h2>Our Commitment</h2>
          <p>At BK Violins, it is our passion to give you the best possible experience and an instrument that is tailored to your needs. With 45 years of experience, Boguslaw Kieta is one of the best and most respected violin makers in the field, and he can ensure an incomparable rental experience for your next string instrument.</p>
        </div>

        <div className="about-section">
          <h2>Our Services</h2>
          <div className="services-grid">
            <div className="service-card">
              <h3>Sales</h3>
              <p>Fine stringed instruments carefully selected and set up by Mr. Kieta himself.</p>
            </div>
            <div className="service-card">
              <h3>Rentals</h3>
              <p>Quality instruments for young musicians, each personally prepared by Mr. Kieta.</p>
            </div>
            <div className="service-card">
              <h3>Repairs</h3>
              <p>Expert repair and restoration services from a master craftsman with over 45 years of experience.</p>
            </div>
            <div className="service-card">
              <h3>Setup</h3>
              <p>Professional instrument setup to ensure optimal playability and sound quality.</p>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="contact-info">
          <p>404-966-1834</p>
          <p>bk@bkviolins.com</p>
          <p>4177 Loch Highland Pkwy NE Roswell, GA 30075</p>
        </div>
        <p className="copyright">©2024. BK Violins. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default About;
