import React from 'react';
import { useCart } from '../context/CartContext.js';
import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../styles/success.css';
import Navbar from './Navbar.js';

const Success = () => {
  const { setRentalCart, setAccessoryCart } = useCart();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');

  useEffect(() => {
    if (type === 'rental') {
      setRentalCart([]);
    } else if (type === 'accessory') {
      setAccessoryCart([]);
    }
  }, [type, setRentalCart, setAccessoryCart]);

  return (
    <div className="success-page">
      <Navbar />
      <div className="success-content">
        <h1>Payment Successful!</h1>
        <p>Thank you for your {type === 'rental' ? 'rental' : 'purchase'}. You will receive a confirmation email shortly.</p>
        <div className="success-buttons">
          <Link to="/rentals" className="button">Continue Shopping</Link>
          <Link to="/" className="button">Return Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Success; 