import React, { useState, useEffect } from 'react';
import '../styles/contact.css';
import Navbar from './Navbar.js';
import Hero from './Hero.js';
import useScrollToTop from '../hooks/useScrollToTop.js';

const Contact = () => {
  useScrollToTop();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comments: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await fetch(`${API_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ 
          ...formData, 
          inquiryType: 'contact'
        }),
      });
      
      if (response.ok) {
        alert('Thank you for your message. We will get back to you soon!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          comments: ''
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Sorry, there was an error sending your message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.contact-section').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="contact-page">
      <Navbar />
      <Hero 
        title="Contact Us" 
        subtitle="Get in touch with us"
      />

      <div className="contact-content">
        <div className="contact-container">
          <div className="contact-info-section">
            <p className="welcome-text">
              Thank you for visiting BK Violins. If you have any further questions regarding our services 
              please fill out the form to your right. We should reply to you within the next business day.
            </p>
            <div className="address-section">
              <h2>Address</h2>
              <p>4177 Loch Highland Pkwy NE</p>
              <p>Roswell, GA 30075</p>
            </div>
            <div className="phone-section">
              <h2>Phone</h2>
              <p>(404) 966-1834</p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="comments">Comments *</label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                rows="4"
                required
              />
            </div>

            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </div>

      <footer className="footer">
        <div className="contact-info">
          <p>404-966-1834</p>
          <p>bk@bkviolins.com</p>
          <p>4177 Loch Highland Pkwy NE Roswell, GA 30075</p>
        </div>
        <p className="copyright">©2024. BK Violins. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Contact;
