import React, { useState, useEffect } from 'react';
import '../styles/purchase.css';
import Navbar from './Navbar.js';
import Hero from './Hero.js';
import useScrollToTop from '../hooks/useScrollToTop.js';

const Purchase = () => {
  useScrollToTop();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    instrument: 'Violin',
    comments: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log('Sending form data:', formData);
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await fetch(`${API_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ 
          ...formData, 
          inquiryType: 'purchase'
        }),
      });
      
      if (response.ok) {
        alert('Thank you for your inquiry. We will get back to you soon!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          instrument: 'Violin',
          comments: ''
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Sorry, there was an error sending your message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.purchase-section').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="purchase-page">
      <Navbar />
      <Hero 
        title="Purchase Instruments"
        subtitle="Find your perfect instrument"
      />

      <div className="purchase-content">
        <div className="info-section">
          <p>
            BK Violins does carry instruments for sale, so if you are in the market 
            it is advised to check with us to see what we have available. Even if we 
            do not have what you are looking for, we will do our best to locate your 
            desired instrument and get it into your hands.
          </p>
          <p>
            Simply fill out the form to inquire about what we have for sale, or if 
            you are in the market for a rare item simply share your requirements with 
            us and we will do our best to locate the item for you.
          </p>
        </div>

        <form className="purchase-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="instrument">Instrument *</label>
            <select
              id="instrument"
              name="instrument"
              value={formData.instrument}
              onChange={handleChange}
              required
            >
              <option value="Violin">Violin</option>
              <option value="Viola">Viola</option>
              <option value="Cello">Cello</option>
              <option value="Bass">Bass</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="comments">Comments *</label>
            <textarea
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              rows="4"
              required
            />
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Submit Inquiry'}
          </button>
        </form>
      </div>

      <footer className="footer">
        <div className="contact-info">
          <p>404-966-1834</p>
          <p>bk@bkviolins.com</p>
          <p>4177 Loch Highland Pkwy NE Roswell, GA 30075</p>
        </div>
        <p className="copyright">©2024. BK Violins. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Purchase;
