import React, { useEffect, useRef, useState } from 'react';
import { useCart } from '../context/CartContext.js';
import cartIcon from '../styles/images/cart-icon.png';
import '../styles/cart.css';

const Cart = () => {
  const { 
    rentalCart,
    accessoryCart, 
    showCart, 
    setShowCart, 
    removeFromRentalCart,
    removeFromAccessoryCart,
    calculateRentalTotal,
    calculateAccessoryTotal
  } = useCart();
  const cartRef = useRef();
  const [email, setEmail] = useState(() => 
    localStorage.getItem('cartFormEmail') || ''
  );
  const [showRentalTerms, setShowRentalTerms] = useState(false);
  const [showAccessoryTerms, setShowAccessoryTerms] = useState(false);
  const [rentalTermsAccepted, setRentalTermsAccepted] = useState(false);
  const [accessoryTermsAccepted, setAccessoryTermsAccepted] = useState(false);
  const [students, setStudents] = useState(() => 
    JSON.parse(localStorage.getItem('cartFormStudents')) || [{
      studentName: '',
      schoolName: '',
      county: '',
      teacherName: '',
      guardian: ''
    }]
  );
  const [deliveryOption, setDeliveryOption] = useState(() => 
    localStorage.getItem('cartDeliveryOption') || 'pickup'
  );
  const [phone, setPhone] = useState(() => 
    localStorage.getItem('cartFormPhone') || ''
  );
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    localStorage.setItem('cartFormEmail', email);
    localStorage.setItem('cartFormPhone', phone);
    localStorage.setItem('cartFormStudents', JSON.stringify(students));
    localStorage.setItem('cartDeliveryOption', deliveryOption);
  }, [email, phone, students, deliveryOption]);

  const addStudent = () => {
    setStudents([...students, {
      studentName: '',
      schoolName: '',
      county: '',
      teacherName: '',
      guardian: ''
    }]);
  };

  const removeStudent = (index) => {
    if (students.length > 1) {
      setStudents(students.filter((_, i) => i !== index));
    }
  };

  const updateStudent = (index, field, value) => {
    const updatedStudents = students.map((student, i) => {
      if (i === index) {
        return { ...student, [field]: value };
      }
      return student;
    });
    setStudents(updatedStudents);
  };

  const calculateTax = (amount) => {
    return parseFloat((amount * 0.06).toFixed(2));
  };

  const handleRentalCheckout = async () => {
    setIsProcessing(true);
    if (!rentalTermsAccepted) {
      alert('Please read and accept the Rental Terms and Conditions before proceeding');
      setIsProcessing(false);
      return;
    }
    
    if (!students[0].studentName || !students[0].schoolName || !students[0].county || !students[0].teacherName) {
      alert('Please fill in all required fields');
      setIsProcessing(false);
      return;
    }

    try {
      if (!email) {
        alert('Please enter your email address');
        setIsProcessing(false);
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert('Please enter a valid email address');
        setIsProcessing(false);
        return;
      }

      if (!phone) {
        alert('Please enter your phone number');
        setIsProcessing(false);
        return;
      }

      const amount = calculateRentalTotal();
      if (amount <= 0 || !Number.isFinite(amount)) {
        throw new Error('Invalid amount');
      }

      const cartItems = rentalCart.map(item => {
        const itemPrice = item.price + (item.maintenanceFee || 0);
        const priceWithTax = Math.round((itemPrice * 1.06) * 100);
        
        return {
          name: `${item.name}${item.size ? ` - ${item.size}` : ''} (includes 6% sales tax)`,
          amount: priceWithTax,
          quantity: 1,
          isSubscription: true
        };
      });

      const API_URL = process.env.REACT_APP_API_URL || 'https://bkviolins-server.onrender.com';
      const response = await fetch(`${API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          items: cartItems,
          email: email,
          phone: phone,
          studentInfo: {
            students: students,
            deliveryOption: deliveryOption
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Checkout failed');
      }

      const { url } = await response.json();
      window.location = url;
    } catch (error) {
      setIsProcessing(false);
      alert(`Payment Error: ${error.message}`);
    }
  };

  const handleAccessoryCheckout = async () => {
    setIsProcessing(true);
    if (!accessoryTermsAccepted) {
      alert('Please read and accept the Purchase Terms and Conditions before proceeding');
      setIsProcessing(false);
      return;
    }
    
    try {
      if (!email) {
        alert('Please enter your email address');
        setIsProcessing(false);
        return;
      }

      if (!phone) {
        alert('Please enter your phone number');
        setIsProcessing(false);
        return;
      }

      if (!students[0].studentName || !students[0].schoolName || !students[0].county || !students[0].teacherName) {
        alert('Please fill in all required student fields');
        setIsProcessing(false);
        return;
      }

      const cartItems = accessoryCart.map(item => {
        const priceWithTax = Math.round((item.price * 1.06) * 100);
        
        return {
          name: `${item.name} (includes 6% sales tax)`,
          amount: priceWithTax,
          quantity: 1,
          isSubscription: false
        };
      });

      const API_URL = process.env.REACT_APP_API_URL || 'https://bkviolins-server.onrender.com';
      const response = await fetch(`${API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          items: cartItems,
          email: email,
          phone: phone,
          studentInfo: {
            students: students,
            deliveryOption: deliveryOption
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Checkout failed');
      }

      const { url } = await response.json();
      window.location = url;
    } catch (error) {
      setIsProcessing(false);
      alert(`Payment Error: ${error.message}`);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const termsModal = document.querySelector('.terms-modal');
      if (termsModal && termsModal.contains(event.target)) {
        return;
      }

      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setShowCart(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setShowCart]);

  return (
    <>
      <div className="cart-icon" onClick={() => setShowCart(!showCart)} ref={cartRef}>
        <img src={cartIcon} alt="Cart" />
        {(rentalCart.length + accessoryCart.length) > 0 && (
          <span className="cart-count">{rentalCart.length + accessoryCart.length}</span>
        )}
      </div>

      <div className={`cart-overlay ${showCart ? 'show' : ''}`}>
        <div className={`cart-modal ${showCart ? 'show' : ''}`} ref={cartRef}>
          <div className="cart-header">
            <h2>Your Cart</h2>
            <button className="close-cart" onClick={() => setShowCart(false)}>×</button>
          </div>

          <div className="cart-content">
            {rentalCart.length === 0 && accessoryCart.length === 0 ? (
              <p className="empty-cart">Your cart is empty</p>
            ) : (
              <>
                <div className="student-info-form">
                  <input
                    type="email"
                    id="customer-email"
                    name="customer-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email *"
                    className="form-input"
                    required
                  />
                  
                  <input
                    type="tel"
                    id="customer-phone"
                    name="customer-phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone Number *"
                    className="form-input"
                    required
                  />

                  <div className="delivery-selection">
                    <label className="delivery-option">
                      <input
                        type="radio"
                        id="delivery-pickup"
                        name="delivery"
                        value="pickup"
                        checked={deliveryOption === 'pickup'}
                        onChange={(e) => setDeliveryOption(e.target.value)}
                      />
                      Local Pickup
                    </label>
                    <label className="delivery-option">
                      <input
                        type="radio"
                        id="delivery-school"
                        name="delivery"
                        value="school"
                        checked={deliveryOption === 'school'}
                        onChange={(e) => setDeliveryOption(e.target.value)}
                      />
                      Delivery to School (if applicable)
                    </label>
                  </div>

                  {students.map((student, index) => (
                    <div key={index} className="student-form-group">
                      <div className="student-header">
                        <h4>Student {index + 1}</h4>
                        {students.length > 1 && (
                          <button 
                            className="remove-student"
                            onClick={() => removeStudent(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      <input
                        type="text"
                        id={`student-${index}-name`}
                        name={`student-${index}-name`}
                        value={student.studentName}
                        onChange={(e) => updateStudent(index, 'studentName', e.target.value)}
                        placeholder="Student's Name *"
                        className="form-input"
                        required
                      />
                      <input
                        type="text"
                        id={`student-${index}-school`}
                        name={`student-${index}-school`}
                        value={student.schoolName}
                        onChange={(e) => updateStudent(index, 'schoolName', e.target.value)}
                        placeholder="School Name *"
                        className="form-input"
                        required
                      />
                      <input
                        type="text"
                        id={`student-${index}-county`}
                        name={`student-${index}-county`}
                        value={student.county}
                        onChange={(e) => updateStudent(index, 'county', e.target.value)}
                        placeholder="County *"
                        className="form-input"
                        required
                      />
                      <input
                        type="text"
                        id={`student-${index}-teacher`}
                        name={`student-${index}-teacher`}
                        value={student.teacherName}
                        onChange={(e) => updateStudent(index, 'teacherName', e.target.value)}
                        placeholder="Orchestra Teacher's Name *"
                        className="form-input"
                        required
                      />
                      <input
                        type="text"
                        id={`student-${index}-guardian`}
                        name={`student-${index}-guardian`}
                        value={student.guardian}
                        onChange={(e) => updateStudent(index, 'guardian', e.target.value)}
                        placeholder="Guardian (if different from customer)"
                        className="form-input"
                      />
                    </div>
                  ))}
                  
                  <button 
                    className="add-student-button"
                    onClick={addStudent}
                  >
                    Add Another Student
                  </button>
                </div>

                {rentalCart.length > 0 && (
                  <div className="cart-section">
                    <h3>Rental Items</h3>
                    <div className="cart-items">
                      {rentalCart.map((item, index) => (
                        <div key={index} className="cart-item">
                          <div className="item-info">
                            <h3>{item.name} {item.size && `- ${item.size}`}</h3>
                            <p>${item.price + (item.maintenanceFee || 0)}/mo</p>
                          </div>
                          <button 
                            className="remove-item"
                            onClick={() => removeFromRentalCart(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="cart-footer">
                      <div className="cart-total">
                        <span>Monthly Payment:</span>
                        <span>${calculateRentalTotal()}/mo</span>
                      </div>
                      <div className="cart-total">
                        <span>Sales Tax (6%):</span>
                        <span>${calculateTax(calculateRentalTotal())}/mo</span>
                      </div>
                      <div className="cart-total">
                        <span>Total with Tax:</span>
                        <span>${(calculateRentalTotal() * 1.06).toFixed(2)}/mo</span>
                      </div>
                      <div className="terms-section">
                        <label className="terms-checkbox">
                          <input
                            type="checkbox"
                            checked={rentalTermsAccepted}
                            onChange={(e) => setRentalTermsAccepted(e.target.checked)}
                          />
                          I accept the <button 
                            className="terms-button"
                            onClick={() => setShowRentalTerms(true)}
                          >
                            Rental Terms and Conditions
                          </button>
                        </label>
                      </div>
                      <button 
                        className="checkout-button"
                        onClick={handleRentalCheckout}
                        disabled={isProcessing}
                      >
                        {isProcessing ? 'Preparing Checkout...' : 'Proceed with Rental Payment'}
                      </button>
                    </div>
                  </div>
                )}

                {accessoryCart.length > 0 && (
                  <div className="cart-section">
                    <h3>Accessories</h3>
                    <div className="cart-items">
                      {accessoryCart.map((item, index) => (
                        <div key={index} className="cart-item">
                          <div className="item-info">
                            <h3>{item.name}</h3>
                            <p>${item.price}</p>
                          </div>
                          <button 
                            className="remove-item"
                            onClick={() => removeFromAccessoryCart(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="cart-footer">
                      <div className="cart-total">
                        <span>One-time Payment:</span>
                        <span>${calculateAccessoryTotal()}</span>
                      </div>
                      <div className="cart-total">
                        <span>Sales Tax (6%):</span>
                        <span>${calculateTax(calculateAccessoryTotal())}</span>
                      </div>
                      <div className="cart-total">
                        <span>Total with Tax:</span>
                        <span>${(calculateAccessoryTotal() * 1.06).toFixed(2)}</span>
                      </div>
                      <div className="terms-section">
                        <label className="terms-checkbox">
                          <input
                            type="checkbox"
                            checked={accessoryTermsAccepted}
                            onChange={(e) => setAccessoryTermsAccepted(e.target.checked)}
                          />
                          I accept the <button 
                            className="terms-button"
                            onClick={() => setShowAccessoryTerms(true)}
                          >
                            Purchase Terms and Conditions
                          </button>
                        </label>
                      </div>
                      <button 
                        className="checkout-button"
                        onClick={handleAccessoryCheckout}
                        disabled={isProcessing}
                      >
                        {isProcessing ? 'Preparing Checkout...' : 'Proceed with Purchase'}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {(rentalCart.length > 0 || accessoryCart.length > 0) && (
            <div className="cart-footer">
              <div className="cart-total">
                <span>Subtotal:</span>
                <span>${calculateRentalTotal() + calculateAccessoryTotal()}</span>
              </div>
              <div className="cart-total">
                <span>Sales Tax (6%):</span>
                <span>${calculateTax(calculateRentalTotal() + calculateAccessoryTotal())}</span>
              </div>
              <div className="cart-total">
                <span>Total with Tax:</span>
                <span>${((calculateRentalTotal() + calculateAccessoryTotal()) * 1.06).toFixed(2)}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {showRentalTerms && (
        <div className="terms-modal">
          <div className="terms-content">
            <h2>Rental Terms and Conditions</h2>
            <div className="terms-text">
              <h3>1. ACCEPTANCE: CANCELLATION</h3>
              <p>Execution of this Agreement and/or acceptance of delivery of the instrument by the Customer constitutes Customer's acceptance of all terms and conditions of the Agreement. This Agreement shall become a contract upon delivery of the instrument by BK Violins (hereinafter referred to as "the Company"). This Agreement may be canceled at any time by Customer by payment of all monthly installments and/or fees incurred through the date of cancellation, and by return of the instrument, its case and all its accessories to the Company. Upon receipt by the Company of the instrument, authorized written termination of this Agreement shall release the Customer from any further liability under the Agreement. No refunds will be given for rental payments made prior to cancellation.</p>
              
              <h3>2. PURCHASE CREDIT</h3>
              <p>BK Violins Premium String Rentals are NOT rent to own. However, we do offer renters a purchase discount of up to 50% off, based on the rental fees collected during the first 60 months of your rental contract. You may return your rental instrument at any time and will have 90 days following the return to apply this towards the purchase of a NEW BK Violins instrument. This purchase discount will equal a maximum of the rental fees (not including the Maintenance and Repair Plan fees & sales taxes) collected during the first 60 months, but not to exceed 50% off the selling price of the NEW BK Violins instrument being purchased. This discount may not be combined with any other discount, trade-in, or special offer. An instrument purchased with this discount must be paid in full at the time of purchase. This discount is only available for the purchase of a new BK Violins instrument; non-BK Violins instruments (consignments, trade-ins, and antique instruments) are excluded. This discount may not be used towards the purchase of a case or bow unless the instrument being purchased is advertised and sold as an outfit by BK Violins. Simply contact us to arrange an instrument trial.</p>
              
              <h3>3. MAINTENANCE</h3>
              <p>The Company will provide any adjustments and maintenance necessitated by normal playing to keep the instrument in perfect playing condition under this Agreement. Maintenance does not cover repair or loss of strings. Under no circumstances shall maintenance or repair be performed by anyone other than the Company. Maintenance coverage policy is void if lease payments are not up to date including all fees associated with this Agreement.</p>
              
              <h3>4. LOSS & DAMAGE</h3>
              <p>Company will not hold the Customer responsible in the event of loss, theft or non-repairable damage of the instrument, except for the payment of the deductible noted below, provided that the following conditions are met, as applicable: 1) Payments must be current; 2) A signed copy of a police report or fire report must accompany claim; 3) if the instrument is lost at school, a report signed by the string teacher must be provided. Deductible: Violin $230, Viola $300, Cello $400, Bass $450. Damaged violin/viola case $45, Damage cello/ bass case $75</p>
              
              <h3>5. DEFAULT</h3>
              <p>Customer shall be in default under this Agreement if Customer: 1) fails to notify the Company if he/she no longer has the credit card accounts listed on page 1 of the Agreement; 2) fails to provide valid replacement cards upon the expiration, termination or cancellation of Customer's credit cards listed on page 1 of the Agreement; 2) fails to inform the Company of any change of address as listed on page 1 of this Agreement; 3) allows maintenance or repair to be performed by anyone other than the Company; 4) fails to pay all amounts due under the Agreement. Upon such default, the Company may 1) charge the Customer's credit cards for the sums due under this Agreement, plus all reasonable costs of collection; 2) charge the full replacement cost of the instrument, unless the instrument is returned promptly upon demand and in good condition except as noted under the Loss & Damage Waiver below.</p>
              
              <h3>6. MISCELLANEOUS</h3>
              <p>Sales tax amounts established in this Agreement will increase or decrease in accordance with any local state, city or federal taxation changes. A facsimile or photocopy of this Agreement shall have the same legal effect as an original. If any provision herein is determined to be unenforceable, the remaining terms of the Agreement shall remain in force and effect. Customer acknowledges receipt of a true copy of this Agreement at the time of delivery of the instrument and authorizes the Company to 1) make whatever credit inquiries it deems necessary in connection with this Rental Agreement or 2) answer questions about its credit experience with the Customer. In the event of a dispute, the provisions of this Agreement shall be governed by Georgia Law.</p>
            </div>
            <button 
              className="close-terms"
              onClick={() => setShowRentalTerms(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showAccessoryTerms && (
        <div className="terms-modal">
          <div className="terms-content">
            <h2>Purchase Terms and Conditions</h2>
            <div className="terms-text">
              <h3>1. ACCEPTANCE: CANCELLATION</h3>
              <p>Execution of this Agreement and/or acceptance of delivery of the instrument by the Customer constitutes Customer's acceptance of all terms and conditions of the Agreement. This Agreement shall become a contract upon delivery of the instrument by BK Violins (hereinafter referred to as "the Company"). This Agreement may be canceled at any time by Customer by payment of all monthly installments and/or fees incurred through the date of cancellation, and by return of the instrument, its case and all its accessories to the Company. Upon receipt by the Company of the instrument, authorized written termination of this Agreement shall release the Customer from any further liability under the Agreement. No refunds will be given for rental payments made prior to cancellation.</p>
              
              <h3>2. PURCHASE CREDIT</h3>
              <p>BK Violins Premium String Rentals are NOT rent to own. However, we do offer renters a purchase discount of up to 50% off, based on the rental fees collected during the first 60 months of your rental contract. You may return your rental instrument at any time and will have 90 days following the return to apply this towards the purchase of a NEW BK Violins instrument. This purchase discount will equal a maximum of the rental fees (not including the Maintenance and Repair Plan fees & sales taxes) collected during the first 60 months, but not to exceed 50% off the selling price of the NEW BK Violins instrument being purchased. This discount may not be combined with any other discount, trade-in, or special offer. An instrument purchased with this discount must be paid in full at the time of purchase. This discount is only available for the purchase of a new BK Violins instrument; non-BK Violins instruments (consignments, trade-ins, and antique instruments) are excluded. This discount may not be used towards the purchase of a case or bow unless the instrument being purchased is advertised and sold as an outfit by BK Violins. Simply contact us to arrange an instrument trial.</p>
              
              <h3>3. MAINTENANCE</h3>
              <p>The Company will provide any adjustments and maintenance necessitated by normal playing to keep the instrument in perfect playing condition under this Agreement. Maintenance does not cover repair or loss of strings. Under no circumstances shall maintenance or repair be performed by anyone other than the Company. Maintenance coverage policy is void if lease payments are not up to date including all fees associated with this Agreement.</p>
              
              <h3>4. LOSS & DAMAGE</h3>
              <p>Company will not hold the Customer responsible in the event of loss, theft or non-repairable damage of the instrument, except for the payment of the deductible noted below, provided that the following conditions are met, as applicable: 1) Payments must be current; 2) A signed copy of a police report or fire report must accompany claim; 3) if the instrument is lost at school, a report signed by the string teacher must be provided. Deductible: Violin $230, Viola $300, Cello $400, Bass $450. Damaged violin/viola case $45, Damage cello/ bass case $75</p>
              
              <h3>5. DEFAULT</h3>
              <p>Customer shall be in default under this Agreement if Customer: 1) fails to notify the Company if he/she no longer has the credit card accounts listed on page 1 of the Agreement; 2) fails to provide valid replacement cards upon the expiration, termination or cancellation of Customer's credit cards listed on page 1 of the Agreement; 2) fails to inform the Company of any change of address as listed on page 1 of this Agreement; 3) allows maintenance or repair to be performed by anyone other than the Company; 4) fails to pay all amounts due under the Agreement. Upon such default, the Company may 1) charge the Customer's credit cards for the sums due under this Agreement, plus all reasonable costs of collection; 2) charge the full replacement cost of the instrument, unless the instrument is returned promptly upon demand and in good condition except as noted under the Loss & Damage Waiver below.</p>
              
              <h3>6. MISCELLANEOUS</h3>
              <p>Sales tax amounts established in this Agreement will increase or decrease in accordance with any local state, city or federal taxation changes. A facsimile or photocopy of this Agreement shall have the same legal effect as an original. If any provision herein is determined to be unenforceable, the remaining terms of the Agreement shall remain in force and effect. Customer acknowledges receipt of a true copy of this Agreement at the time of delivery of the instrument and authorizes the Company to 1) make whatever credit inquiries it deems necessary in connection with this Rental Agreement or 2) answer questions about its credit experience with the Customer. In the event of a dispute, the provisions of this Agreement shall be governed by Georgia Law.</p>
            </div>
            <button 
              className="close-terms"
              onClick={() => setShowAccessoryTerms(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart; 