import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/cancel.css';
import Navbar from './Navbar.js';

const Cancel = () => {
  return (
    <div className="cancel-page">
      <Navbar />
      <div className="cancel-content">
        <h1>Payment Cancelled</h1>
        <p>Your payment was cancelled. Your cart items are still saved if you'd like to try again.</p>
        <div className="cancel-buttons">
          <Link to="/rentals" className="button">Return to Shop</Link>
        </div>
      </div>
    </div>
  );
};

export default Cancel; 